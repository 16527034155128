export function redirectWithoutSelectedRep() {
  const paths = ['dia-da-representante', 'selecao-conecta', 'manicure-l3p2']
  const isRepresentativeSelected = localStorage.getItem('representative')

  if (!isRepresentativeSelected) {
    paths.forEach((path) => {
      if (window.location.pathname.includes(path)) {
        window.location.href = '/'
      }
    })
  }
}
