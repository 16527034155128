import React, { useEffect } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { gql } from '@vtex/gatsby-plugin-graphql'
import { navigate, graphql } from 'gatsby'
import type { SearchParamsState } from '@vtex/store-sdk'
import { Helmet } from 'react-helmet'
import {
  HybridWrapper,
  useQuery,
  useSearchParamsFromUrl,
} from '@vtex/gatsby-theme-store'
import Layout from 'src/components/Layout'
import AboveTheFoldPreview from 'src/views/productListLandingPage/components/AboveTheFoldPreview'
import ProductListLandingPage from 'src/views/productListLandingPage'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'

import { useQueryVariablesFromSearchParams } from '../../sdk/search/useQueryVaraiblesFromSearchParams'
import { BrowserLandingPageQuery } from './__generated__/BrowserLandingPageQuery.graphql'
import type {
  BrowserLandingPageQueryQuery,
  BrowserLandingPageQueryQueryVariables,
} from './__generated__/BrowserLandingPageQuery.graphql'
import type {
  ServerLandingPageQueryQuery,
  ServerLandingPageQueryQueryVariables,
} from './__generated__/ServerLandingPageQuery.graphql'

const pageInfo = { size: Number(process.env.GATSBY_STORE_PLP_ITEMS_PER_PAGE) }

const SearchPage: FC<PageProps> = (props) => {
  const { isCollaboratorStore } = useStoreType()

  useEffect(() => {
    if (
      !isCollaboratorStore &&
      props.location.pathname.includes('/outletcolaborador')
    ) {
      navigate('/outlet')
    }
  }, [isCollaboratorStore, props.location.pathname])

  const { location, data: serverData } = props
  const qs = new URLSearchParams(location?.search || '')
  const map = qs.get('map')
  const { clusterId } =
    serverData.cmsPlLandingPage?.parameters?.plLandingPageParams ?? {}

  const slug =
    serverData.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.slug ??
    '/s/'

  if (!map) {
    qs.append('map', 'sort')

    location.href = `${location.origin}${
      location.pathname
    }/score-desc?${qs.toString()}`
    location.search = qs.toString()
  }

  const searchParams = useSearchParamsFromUrl(location)

  searchParams.selectedFacets.forEach((selectedFacet, idx) => {
    if (selectedFacet.key === 'priceRange') {
      searchParams.selectedFacets[idx].value = selectedFacet.value
        .replace(/%20|-/g, ' ')
        .replace('to', 'TO')
    }

    if (
      isCollaboratorStore &&
      searchParams.selectedFacets[0]?.value === '1580'
    ) {
      searchParams.selectedFacets[1].value = '3'
    }
  })

  const variables = useQueryVariablesFromSearchParams(
    searchParams,
    pageInfo,
    clusterId,
    slug
  )

  searchParams.selectedFacets =
    variables.selectedFacets as SearchParamsState['selectedFacets']

  const { data: browserData } = useQuery<
    BrowserLandingPageQueryQuery,
    BrowserLandingPageQueryQueryVariables
  >({
    ...BrowserLandingPageQuery,
    suspense: true,
    variables,
  })

  if (browserData == null) {
    throw new Error('Something went wrong while fetching the data')
  }

  const data = { ...browserData, ...serverData }

  return (
    <ProductListLandingPage
      data={data}
      searchParams={searchParams}
      pageInfo={pageInfo}
    />
  )
}

type PageProps = GatsbyPageProps<
  ServerLandingPageQueryQuery,
  ServerLandingPageQueryQueryVariables & { slug: string }
>

const Page: FC<PageProps> = (props) => {
  const canonicalMetatag = `https://www.avon.com.br/${props.location.pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace(/\/$/, '')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
      </Helmet>
      <Layout>
        <HybridWrapper fallback={<AboveTheFoldPreview />}>
          <SearchPage {...props} />
        </HybridWrapper>
      </Layout>
    </>
  )
}

/**
 * This query run on the browser
 * */
export const query = gql`
  query BrowserLandingPageQuery(
    $selectedFacets: [VTEX_SelectedFacetInput!]!
    $sort: String!
  ) {
    vtex {
      productSearch(
        to: 11
        from: 0
        orderBy: $sort
        selectedFacets: $selectedFacets
        hideUnavailableItems: false
        simulationBehavior: skip
      ) {
        products {
          ...ProductSummary_product
        }
        recordsFiltered
      }
      facets(
        selectedFacets: $selectedFacets
        operator: or
        behavior: "Static"
        removeHiddenFacets: true
      ) {
        breadcrumb {
          href
          name
        }
        facets {
          name
          type
          values {
            key
            name
            value
            selected
            quantity
            range {
              from
              to
            }
          }
        }
      }
    }
  }
`

/**
 * This query run during SSG
 * */
export const serverSideQuery = graphql`
  query ServerLandingPageQuery($id: String!) {
    cmsPlLandingPage(id: { eq: $id }) {
      name
      parameters {
        plLandingPageParams {
          seo {
            title
            slug
            description
          }
          clusterId
        }
      }
      sections {
        name
        props: data
      }
    }
  }
`

export default Page
