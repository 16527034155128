import React from 'react'
import { Box } from '@vtex/store-ui'

import Question from './Question'
import LdJson from './LdJson'
import { container, questionsContainer } from './styles'

interface Props {
  faq: {
    title: string
    description: string
    questions: {
      items: Array<{
        question: string
        shortAnswer: string
        fullAnswer: string
      }>
    }
  }
}

const CollectionFAQ = ({ faq }: Props) => {
  const { title, description, questions } = faq

  return (
    <>
      <LdJson questions={faq} />
      <Box sx={container}>
        <h1>{title}</h1>
        <p className="description">{description}</p>

        <Box sx={questionsContainer}>
          {questions.items.map((question) => (
            <Question key={question.fullAnswer} question={question} />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default CollectionFAQ
