import type { SxStyleProp } from '@vtex/store-ui'

import { collectionColors } from '../CollectionText/styles'

export const container: SxStyleProp = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: ['90vw', '800px'],
  mx: 'auto',
  mb: '4rem',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Zona Pro, sans-serif',

  h1: {
    textAlign: ['left', 'center'],
    width: '100%',
    fontSize: ['1.25rem', '2rem'],
    lineHeight: ['1.8125rem', '2.75rem'],
    margin: 0,
  },

  '.description': {
    textAlign: ['left', 'center'],
    fontSize: ['1rem'],
    lineHeight: ['1.1875rem', '1.375rem'],
    margin: 0,
    mt: ['0.875rem'],
    mb: ['2.625rem'],
  },
}

export const questionsContainer: SxStyleProp = {
  display: 'flex',
  flexDirection: 'column',
  gap: ['.75rem'],
}

export const questionContainer: SxStyleProp = {
  backgroundColor: '#F6F6F6',
  borderRadius: '14px',
  padding: ['.75rem 1.25rem', '.5625rem 3.4375rem'],
  position: 'relative',

  h2: {
    margin: 0,
    fontSize: ['1.125rem', '1.0625rem'],
    lineHeight: ['1.5625rem', '1.4375rem'],
    maxWidth: ['95%', 'unset'],
  },

  '.arrow': {
    position: 'absolute',
    right: ['5%'],
    top: ['12px'],
    cursor: 'pointer',

    svg: {
      width: ['1rem'],
      height: ['1rem'],
    },
  },

  p: {
    margin: 0,
    fontSize: ['1rem'],
    lineHeight: ['1.375rem'],

    my: ['0.75rem', '1rem'],
  },

  '.isClosed': {
    p: {
      overflow: ['hidden', 'unset'],
      width: ['100%'],
      display: ['-webkit-box', 'block'],
      WebkitLineClamp: ['3', 'unset'],
      WebkitBoxOrient: ['vertical', 'unset'],
    },
  },

  a: {
    color: collectionColors.pink,
  },

  ul: {
    paddingLeft: '2rem',
    margin: 0,
    mt: '1rem',
  },
}
