import type { SxStyleProp } from '@vtex/store-ui'

export const collectionColors = {
  pink: '#F05496',
}

export const container: SxStyleProp = {
  display: 'flex',
  flexDirection: 'column',
  width: '90vw',
  maxWidth: ['', '1300px'],
  margin: '1.4rem auto 1rem',

  h1: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: ['1.25rem', '2rem'],
    lineHeight: ['1.8125rem', '2.75rem'],
    color: '#000',
  },
}

export const buttons: SxStyleProp = {
  display: 'flex',
  alignItems: 'flex-end',
  flexWrap: ['nowrap', 'wrap'],
  gap: ['0.3125rem', '0.625rem'],
  overflowX: ['auto', 'unset'],
  pr: ['2.5rem', '0'],
  minWidth: ['max-content', 'auto'],

  a: {
    fontFamily: 'Lato, sans-serif',
    textDecoration: 'none',
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '1.375rem',
    color: '#000',
    display: 'grid',
    placeItems: 'center',
    width: 'auto',
    padding: ['0.5rem 1.375rem', '0.875rem 1.375rem'],
    border: `1px solid ${collectionColors.pink}`,
    borderRadius: '25px',
  },

  span: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 700,
    fontSize: ['1rem'],

    color: collectionColors.pink,
    cursor: 'pointer',

    svg: {
      fill: collectionColors.pink,
    },
  },

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}

export const description: SxStyleProp = {
  h2: {
    fontFamily: 'Lato, sans-serif',
    fontSize: ['1rem'],
    lineHeight: ['1.1875rem'],
    color: '#000',
    fontWeight: 400,
    margin: 0,
    mt: ['1.5rem'],
  },

  span: {
    color: collectionColors.pink,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

export const wrapper: SxStyleProp = {
  width: '100%',
  overflow: 'auto',
  height: ['50px', 'auto'],
}
