import { Box, Heading } from '@vtex/store-ui'
import React, { useState } from 'react'
import { ArrowDownIcon } from 'src/components/icons'
import RichText from 'src/components/ui/RichText'

import { questionContainer } from './styles'

interface Props {
  question: {
    question: string
    shortAnswer: string
    fullAnswer: string
  }
}

const Question = ({ question }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const { question: ask, fullAnswer, shortAnswer } = question

  return (
    <Box sx={questionContainer}>
      <Heading as="h2">{ask}</Heading>

      <Box
        className="arrow"
        onClick={() => setIsOpen(!isOpen)}
        role="button"
        sx={{ transform: isOpen ? 'rotate(180deg)' : 'none' }}
      >
        <ArrowDownIcon />
      </Box>

      <Box className={isOpen ? 'isOpen' : 'isClosed'}>
        <RichText richText={shortAnswer} />
      </Box>

      <Box sx={{ display: isOpen ? 'contents' : 'none' }}>
        <RichText richText={fullAnswer} />
      </Box>
    </Box>
  )
}

export default Question
