import draftToHtml from 'draftjs-to-html'
import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  questions: {
    title: string
    description: string
    questions: {
      items: Array<{
        question: string
        shortAnswer: string
        fullAnswer: string
      }>
    }
  }
}

const LdJson = ({ questions }: Props) => {
  const schemaLdJson = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.questions.items.map(
      ({ question, shortAnswer, fullAnswer }) => {
        const shortAnswerFormatted = JSON.parse(shortAnswer)
        const fullAnswerFormatted = JSON.parse(fullAnswer)

        const shortHtmlText = draftToHtml(shortAnswerFormatted)
        const fullHtmlText = draftToHtml(fullAnswerFormatted)

        const answer = shortHtmlText + fullHtmlText

        return {
          '@type': 'Question',
          name: question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: answer,
          },
        }
      }
    ),
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaLdJson)}</script>
    </Helmet>
  )
}

export default LdJson
