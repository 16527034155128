/** @jsx jsx */
import { useEffect } from 'react'
import type { FC } from 'react'
import { Container, Flex, Box, jsx } from '@vtex/store-ui'
import FacetedProductList from 'src/components/common/FacetedProductList'
import RichText from 'src/components/ui/RichText'
import PageBanner from 'src/components/common/PageBanner'
import { redirectWithoutSelectedRep } from 'src/utils/redirectWithoutRep'
import { FaixaColab } from 'src/components/common/FaixaColab'

import type { ProductListLandingPageProps } from '..'

type Props = ProductListLandingPageProps

const AboveTheFold: FC<Props> = ({ data }) => {
  useEffect(() => redirectWithoutSelectedRep(), [])

  const bannerSection = data?.cmsPlLandingPage?.sections[0]?.props

  const isCarnavalPage =
    data.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.slug?.includes(
      '/carnaval-avon'
    )

  const hasRichText = data.cmsPlLandingPage?.sections.filter(
    (section: { name: string }) => {
      if (section.name === 'RichText') {
        return section
      }

      return undefined
    }
  )

  const isOutletColaboradorPage =
    data.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.slug?.includes(
      'outletcolaborador'
    )

  return (
    <Box>
      {bannerSection && (
        <PageBanner
          heightInline={['290px', '535px', 'auto']}
          heightBanner={{ height: 550, width: 1920 }}
          plLandingPage
          {...bannerSection}
        />
      )}

      {isOutletColaboradorPage && <FaixaColab />}

      {isCarnavalPage && hasRichText && (
        <Flex
          sx={{
            width: '95vw',
            margin: '0 auto',
            fontFamily: 'Zona Pro, sans-serif',
          }}
        >
          <RichText richText={hasRichText[0].props.content} />
        </Flex>
      )}

      <Container sx={{ maxWidth: '1440px' }}>
        <Flex>
          <FacetedProductList data={data} />
        </Flex>
      </Container>

      {isCarnavalPage && hasRichText && (
        <Flex
          sx={{
            width: '95vw',
            margin: '0 auto',
            fontFamily: 'Zona Pro, sans-serif',
          }}
        >
          <RichText richText={hasRichText[1].props.content} />
        </Flex>
      )}
    </Box>
  )
}

export default AboveTheFold
