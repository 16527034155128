import React, { lazy } from 'react'
import { useLocation } from '@reach/router'
import { usePlpPixelEffect, SearchProvider } from '@vtex/gatsby-theme-store'
import { View } from 'src/components/ui/View'
import type { FC } from 'react'
import type { SearchParamsState } from '@vtex/store-sdk'
import type { BrowserLandingPageQueryQuery } from 'src/pages/{CmsPlLandingPage.parameters__plLandingPageParams__seo__slug}/__generated__/BrowserLandingPageQuery.graphql'
import type { ServerLandingPageQueryQuery } from 'src/pages/{CmsPlLandingPage.parameters__plLandingPageParams__seo__slug}/__generated__/ServerLandingPageQuery.graphql'
import CollectionFAQ from 'src/components/collection/FAQ'
import type { OutletColaboradorQueryQuery } from 'src/pages/outletcolaborador/__generated__/OutletColaboradorQuery.graphql'

import Seo from './Seo'
import AboveTheFold from './components/AboveTheFold'

const loader = () => import('./components/BelowTheFold')
const BelowTheFold = lazy(loader)

export interface ProductListLandingPageProps {
  data:
    | (BrowserLandingPageQueryQuery & ServerLandingPageQueryQuery)
    | (OutletColaboradorQueryQuery & BrowserLandingPageQueryQuery)
  searchParams: SearchParamsState
  pageInfo: { size: number }
}

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
    preloader: loader,
  },
} as const

const ProductListLandingPage: FC<ProductListLandingPageProps> = (props) => {
  const {
    data,
    searchParams,
    pageInfo: { size },
  } = props

  const totalCount = data.vtex.productSearch?.recordsFiltered ?? 0
  const location = useLocation()

  usePlpPixelEffect({
    searchParams,
    totalCount,
    location,
  })

  const faq = data.cmsPlLandingPage?.sections.find(
    (section) => section.name === 'FAQ Department'
  )

  return (
    <SearchProvider
      searchParams={searchParams}
      data={data}
      pageInfo={{
        size,
        total: Math.ceil(totalCount / size),
      }}
    >
      <View {...ViewComponents} data={props} />
      {!!faq && <CollectionFAQ faq={faq.props} />}
    </SearchProvider>
  )
}

export default ProductListLandingPage
